import { createApp } from 'vue';
import pinia from '/@/stores/index';
import App from '/@/App.vue';
import router from '/@/router';
import { directive } from '/@/directive/index';
import { i18n } from '/@/i18n/index';
import other from '/@/utils/other';
import errorHandler from '/@/utils/errorHandler';

import ElementPlus from 'element-plus';
import '/@/theme/index.scss';
import VueGridLayout from 'vue-grid-layout';

import { Splitpanes, Pane } from 'splitpanes';
import 'animate.css'; // 动画库
import 'splitpanes/dist/splitpanes.css';

const app = createApp(App);

directive(app);
other.elSvg(app);

app.component('Splitpanes', Splitpanes);
app.component('Pane', Pane);

//全局代码错误捕捉
app.config.errorHandler = errorHandler;
app.use(pinia)
app.use(router)
app.use(ElementPlus)
app.use(i18n)
app.use(VueGridLayout)
app.mount('#app');
