import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import { ElMessage } from 'element-plus';
import { Local, Session } from '/@/utils/storage';
import router from '/@/router';
import { getFileName } from '/@/utils/download';

// 配置新建一个 axios 实例
export const service = axios.create({
	baseURL: window.__env__.VITE_API_URL as any,
	timeout: 50000,
	headers: { 'Content-Type': 'application/json' },
});

// token 键定义
export const accessTokenKey = 'access-token';
export const refreshAccessTokenKey = `x-${accessTokenKey}`;

// 获取 token
export const getToken = () => {
	return Local.get(accessTokenKey);
};

// 清除 token
export const clearAccessTokens = () => {
	Local.remove(accessTokenKey);
	Local.remove(refreshAccessTokenKey);

	// 清除其他
	Session.clear();

	// // 刷新浏览器
	// window.location.reload();
	// 跳转到登录页
	router.replace('/login');
};

// axios 默认实例
export const axiosInstance: AxiosInstance = axios;

// 添加请求拦截器
service.interceptors.request.use(
	(config) => {
		// // 在发送请求之前做些什么 token
		// if (Session.get('token')) {
		// 	(<any>config.headers).common['Authorization'] = `${Session.get('token')}`;
		// }

		// 获取本地的 token
		const accessToken = Local.get(accessTokenKey);
		if (accessToken && accessToken !== 'invalid_token') {
			// 将 token 添加到请求报文头中
			config.headers!['Authorization'] = `Bearer ${accessToken}`;

			// 判断 accessToken 是否过期
			const jwt: any = decryptJWT(accessToken);
			const exp = getJWTDate(jwt.exp as number);

			// token 已经过期
			if (new Date() >= exp) {
				// 获取刷新 token
				const refreshAccessToken = Local.get(refreshAccessTokenKey);

				// 携带刷新 token
				if (refreshAccessToken) {
					config.headers!['X-Authorization'] = `Bearer ${refreshAccessToken}`;
				}
			}
			// debugger
			// get请求映射params参数
			if (config.method?.toLowerCase() === 'get' && config.data) {
				let url = config.url + '?' + tansParams(config.data);
				url = url.slice(0, -1);
				config.data = {};
				config.url = url;
			}
		}

		// 获取前端设置的语言
		const globalI18n = Local.get('themeConfig')['globalI18n'];
		if (globalI18n) {
			// 添加到请求报文头中
			config.headers!['Accept-Language'] = globalI18n;
		}
		return config;
	},
	(error) => {
		// 对请求错误做些什么
		return Promise.reject(error);
	}
);

// 添加响应拦截器
service.interceptors.response.use(
	(res: AxiosResponse) => {
		// 读取响应报文头 token 信息
		const accessToken = res.headers[accessTokenKey];
		const refreshAccessToken = res.headers[refreshAccessTokenKey];

		// 判断是否是无效 token
		if (accessToken === 'invalid_token') {
			// 重新登录
			clearAccessTokens();
		}
		// 保存 Token
		if (refreshAccessToken && accessToken) {
			Local.set(accessTokenKey, accessToken);
			Local.set(refreshAccessTokenKey, refreshAccessToken);
		}

		// 如果没有规范化数据，直接返回
		if (!res.data) {
			return Promise.resolve(res);
		}

		if (res.headers['content-type'] == 'application/octet-stream') {
			var fileName = getFileName(res.headers['content-disposition']);
			var file = new File([res.data], fileName);
			return Promise.resolve(file);
		}

		// 获取状态码和返回数据
		const serve = res.data;

		// 处理自行扩展的 axios 数据返回类型
		res.result = serve.data;
		res.code = serve.statusCode;
		res.errors = serve.errors;
		res.extras = serve.extras;
		res.timestamp = serve.timestamp;
		res.succeeded = serve.succeeded;

		// 处理规范化结果错误
		if (!serve.succeeded) {
			// 响应拦截及自定义处理
			if (serve.statusCode === 401) {
				ElMessage.error(serve.errors ? serve.errors : '登录失效！请您重新登录');
				clearAccessTokens();
				return Promise.reject(serve);
			}
			// 默认仅处理简单错误提示，复杂类型错误提示由调用方自行处理
			if (serve.errors && typeof serve.errors == 'string') {
				ElMessage.error(serve.errors);
			}
			return Promise.reject(serve);
		} else {
			// 处理简单成功提示
			if (serve.statusCode === 204) {
				ElMessage.success('操作成功！');
			}
		}

		// 直接返回 res.data 数据
		return Promise.resolve(serve);
	},
	(error: AxiosError) => {
		// 请求超时 && 网络错误单独判断，没有 response
		if (error.message.indexOf('timeout') !== -1) ElMessage.error('请求超时！请您稍后重试');

		if (error.message.indexOf('Network Error') !== -1) ElMessage.error('网络错误！请您稍后重试');

		const { response } = error;
		// 处理响应错误
		if (response) {
			// 统一处理状态错误提示
			checkStatus(response.status);
			// 401 重新登录
			if (response.status === 401) {
				clearAccessTokens();
			}
		}

		// 服务器结果都没有返回(可能服务器错误可能客户端断网)，断网处理:可以跳转到断网页面
		if (!window.navigator.onLine) router.replace('/500');

		// 其他未处理异常，直接抛出
		return Promise.reject(error);
	}
);

/**
 * @description: 校验网络请求状态码
 * @param {Number} status
 * @return void
 */
export const checkStatus = (status: number) => {
	switch (status) {
		case 400:
			ElMessage.error('请求失败！请您稍后重试');
			break;
		case 401:
			ElMessage.error('登录失效！请您重新登录');
			break;
		case 403:
			ElMessage.error('当前账号无权限访问！');
			break;
		case 404:
			ElMessage.error('你所访问的资源不存在！');
			break;
		case 405:
			ElMessage.error('请求方式错误！请您稍后重试');
			break;
		case 408:
			ElMessage.error('请求超时！请您稍后重试');
			break;
		case 500:
			ElMessage.error('服务异常！');
			break;
		case 502:
			ElMessage.error('网关错误！');
			break;
		case 503:
			ElMessage.error('服务不可用！');
			break;
		case 504:
			ElMessage.error('网关超时！');
			break;
		default:
			ElMessage.error('请求失败！');
	}
};

/**
 *  参数处理
 * @param {*} params  参数
 */
export function tansParams(params: any) {
	let result = '';
	for (const propName of Object.keys(params)) {
		const value = params[propName];
		var part = encodeURIComponent(propName) + '=';
		if (value !== null && value !== '' && typeof value !== 'undefined') {
			if (typeof value === 'object') {
				for (const key of Object.keys(value)) {
					if (value[key] !== null && value[key] !== '' && typeof value[key] !== 'undefined') {
						let params = propName + '[' + key + ']';
						var subPart = encodeURIComponent(params) + '=';
						result += subPart + encodeURIComponent(value[key]) + '&';
					}
				}
			} else {
				result += part + encodeURIComponent(value) + '&';
			}
		}
	}
	return result;
}

/**
 * 解密 JWT token 的信息
 * @param token jwt token 字符串
 * @returns <any>object
 */
export function decryptJWT(token: string): any {
	token = token.replace(/_/g, '/').replace(/-/g, '+');
	var json = decodeURIComponent(escape(window.atob(token.split('.')[1])));
	return JSON.parse(json);
}

/**
 * 将 JWT 时间戳转换成 Date
 * @description 主要针对 `exp`，`iat`，`nbf`
 * @param timestamp 时间戳
 * @returns Date 对象
 */
export function getJWTDate(timestamp: number): Date {
	return new Date(timestamp * 1000);
}

/**
 * 统一返回值
 * @export
 * @interface UniformResult
 */
export interface UniformResult<T> {
	/**
	 * 状态码
	 * @type {number}
	 * @memberof UniformResult
	 */
	statusCode: number;
	/**
	 * 数据
	 * @memberof UniformResult
	 */
	data?: T;
	/**
	 * 执行成功
	 * @type {boolean}
	 * @memberof UniformResult
	 */
	succeeded: boolean;
	/**
	 * 错误信息
	 * @type {object}
	 * @memberof UniformResult
	 */
	errors?: string | object | Record<string, string[]>;
	/**
	 * 附加数据
	 * @type {object}
	 * @memberof UniformResult
	 */
	extras?: object;
	/**
	 * 时间戳
	 * @type {number}
	 * @memberof UniformResult
	 */
	timestamp: number;
}

/**
 * 统一的分页格式
 * @export
 * @interface PagedResult
 */
export interface PagedResult<T> {
	/**
	 * 当前页集合
	 * @type {Array<T>}
	 * @memberof PagedResult
	 */
	rows: Array<T>;
	/**
	 * 当前页码
	 * @type {number}
	 * @memberof PagedResult
	 */
	pageNo: number;
	/**
	 * 每页条数
	 * @type {number}
	 * @memberof PagedResult
	 */
	pageSize: number;
	/**
	 * 总条数
	 * @type {number}
	 * @memberof PagedResult
	 */
	totalRows: number;
	/**
	 * 总页数
	 * @type {number}
	 * @memberof PagedResult
	 */
	totalPage: number;
	/**
	 * 是否有上一页
	 * @type {boolean}
	 * @memberof PagedResult
	 */
	hasPrevPages: boolean;
	/**
	 * 是否有下一页
	 * @type {boolean}
	 * @memberof PagedResult
	 */
	hasNextPages: boolean;
}
/**
 * 自定义更新参数模型
 * @export
 * @interface CustomUpdateModel<T>
 */
export interface CustomUpdateModel<T> {
    /**
     *
     * @type {T}
     */
    'data': T;
    /**
     * 主表更新字段
     * @type {Array<string>}
     */
    'updateFields'?: Array<string> | null;
    /**
     * 子表更新字段
     * @type {{ [key: string]: Array<string>; }}
     */
    'childUpdateFields'?: { [key: string]: Array<string>; } | null;
}

/**
 * 框架实体类的基类
 * @export
 * @interface BaseEntity
 */
export interface BaseEntity {
    /**
     * 主键，0 表示待新增
     * @type {number}
     */
    'id': number;
    /**
     * 乐观锁
     * @type {number}
     */
    'ver': number;
    /**
     * 创建人ID
     * @type {number}
     */
    'createdBy': number;
    /**
     * 创建者姓名
     * @type {string}
     */
    'createdName'?: string | null;
    /**
     * 创建时间
     * @type {string}
     */
    'createdTime': string;
    /**
     * 修改人ID
     * @type {number}
     */
    'updatedBy'?: number | null;
    /**
     * 修改者姓名
     * @type {string}
     */
    'updatedName'?: string | null;
    /**
     * 修改时间
     * @type {string}
     */
    'updatedTime'?: string | null;
    /**
     * 是否启用
     * @type {boolean}
     */
    'isValid': boolean;
    /**
     * 是否删除
     * @type {boolean}
     */
    'isDeleted': boolean;
    /**
     * 所属机构ID
     * @type {number}
     */
    'orgId': number;
    /**
     * 所属机构名称
     * @type {string}
     */
    'orgName'?: string | null;
}

// 导出 axios 实例
export default service;
